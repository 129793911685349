var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: { rounded: "0", "offset-y": "", dark: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b({ attrs: { icon: "" } }, "v-btn", attrs, false),
                  on
                ),
                [
                  _vm.locale
                    ? _c("span", [_vm._v(_vm._s(_vm.$t(_vm.locale.code)))])
                    : _c("span", [_vm._v("EN")]),
                ]
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-list",
        { staticClass: "pa-0" },
        [
          _c(
            "v-list-item-group",
            {
              attrs: { mandatory: "" },
              on: { change: _vm.changeLocale },
              model: {
                value: _vm.locale,
                callback: function ($$v) {
                  _vm.locale = $$v
                },
                expression: "locale",
              },
            },
            _vm._l(_vm.locales, function (item) {
              return _c(
                "v-list-item",
                { key: item.locale, attrs: { value: item } },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-img", { attrs: { src: item.flag } })],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [
                        _vm._v(" " + _vm._s(_vm.$t(item.text)) + " "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }