var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        {
          staticClass: "px-6",
          attrs: {
            dark: "",
            app: "",
            "clipped-left": "",
            "elevate-on-scroll": "",
            fixed: "",
            height: 60,
          },
        },
        [
          _c("v-app-bar-nav-icon", {
            staticClass: "hidden-lg-and-up",
            on: {
              click: function ($event) {
                _vm.drawer = true
              },
            },
          }),
          _c(
            "router-link",
            { attrs: { to: { name: "Home" } } },
            [
              _vm.$vuetify.breakpoint.smAndUp
                ? _c("v-img", {
                    staticClass: "shrink mr-2",
                    attrs: {
                      alt: "braendz",
                      src: require("@/assets/logos/braendz-light.svg"),
                      height: "30",
                      width: "150",
                      contain: "",
                      position: "left",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm.$vuetify.breakpoint.lgAndUp
            ? _c(
                "v-tabs",
                {
                  staticClass: "mr-10",
                  attrs: {
                    centered: "",
                    "hide-slider": "",
                    optional: "",
                    "background-color": "transparent",
                  },
                },
                [
                  _c(
                    "v-tab",
                    { attrs: { to: { name: "Create" } } },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("fa-light fa-rocket"),
                      ]),
                      _vm._v(
                        " " + _vm._s(_vm.$t("toolbar.links.create")) + " "
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-tab",
                    { attrs: { to: { name: "Search" } } },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("fa-light fa-file-magnifying-glass"),
                      ]),
                      _vm._v(
                        " " + _vm._s(_vm.$t("toolbar.links.search")) + " "
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-tab",
                    { attrs: { href: "https://gpt.braendz.com" } },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v(_vm._s("$chat-gpt")),
                      ]),
                      _vm._v(" " + _vm._s(_vm.$t("toolbar.links.gpt")) + " "),
                    ],
                    1
                  ),
                  _vm.userAccount
                    ? _c(
                        "v-tab",
                        { attrs: { to: { name: "MyBraendz" } } },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("fa-light fa-user-magnifying-glass"),
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("toolbar.links.myBraendz")) +
                              " "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$env.featureSubscriptionActive()
                    ? _c(
                        "v-tab",
                        { attrs: { to: { name: "Pricing" } } },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("fa-light fa-circle-euro"),
                          ]),
                          _vm._v(
                            " " + _vm._s(_vm.$t("toolbar.links.pricing")) + " "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-tab",
                    { attrs: { to: { name: "Insights" } } },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("fa-light fa-chart-pie"),
                      ]),
                      _vm._v(
                        " " + _vm._s(_vm.$t("toolbar.links.insights")) + " "
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c("v-spacer"),
          _c("LocaleToolbarItem"),
          _c("NotificationsToolbarItem"),
          _c("AccountToolbarItem"),
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          attrs: { mandatory: "", dark: "", fixed: "", temporary: "" },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "v-list-item",
            { attrs: { to: { name: "Home" } } },
            [
              _c(
                "v-list-item-content",
                [
                  _c("v-img", {
                    staticClass: "shrink",
                    attrs: {
                      alt: "braendz",
                      src: require("@/assets/logos/braendz-light.svg"),
                      height: "30",
                      width: "150",
                      contain: "",
                      position: "left",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-list",
            { attrs: { nav: "", dense: "" } },
            [
              _c(
                "v-list-item-group",
                {
                  staticClass: "white--text text--darken-2",
                  attrs: { "active-class": "white--text" },
                },
                [
                  _c(
                    "v-list-item",
                    { attrs: { to: { name: "Create" }, link: "" } },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("fa-light fa-rocket")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(_vm.$t("toolbar.links.create"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    { attrs: { to: { name: "Search" }, link: "" } },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-icon", [
                            _vm._v("fa-light fa-file-magnifying-glass"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(_vm.$t("toolbar.links.search"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    { attrs: { href: "https://gpt.braendz.com", link: "" } },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v(_vm._s("$chat-gpt"))])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(_vm.$t("toolbar.links.gpt"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.userAccount
                    ? _c(
                        "v-list-item",
                        { attrs: { to: { name: "MyBraendz" }, link: "" } },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", [
                                _vm._v("fa-light fa-user-magnifying-glass"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  _vm._s(_vm.$t("toolbar.links.myBraendz"))
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$env.featureSubscriptionActive()
                    ? _c(
                        "v-list-item",
                        { attrs: { to: { name: "Pricing" }, link: "" } },
                        [
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v("fa-light fa-circle-euro")])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(_vm.$t("toolbar.links.pricing"))),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-list-item",
                    { attrs: { to: { name: "Insights" }, link: "" } },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("fa-light fa-chart-pie")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(_vm.$t("toolbar.links.insights"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }