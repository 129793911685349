var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-footer",
    { attrs: { dark: "", padless: "" } },
    [
      _c(
        "v-card",
        { staticClass: "flex", attrs: { flat: "", tile: "" } },
        [
          _c(
            "v-card-text",
            { staticClass: "py-2" },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "space-between" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "flex",
                              attrs: {
                                flat: "",
                                tile: "",
                                color: "transparent",
                              },
                            },
                            [
                              _c(
                                "v-card-title",
                                [
                                  _c("v-img", {
                                    staticClass: "shrink mr-2",
                                    attrs: {
                                      alt: "Braendz Logo",
                                      src: require("@/assets/logos/braendz-light.svg"),
                                      transition: "scale-transition",
                                      width: "100",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                [
                                  _c("span", {
                                    staticClass: "d-block",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t("footer.serviceOfConxepta")
                                      ),
                                    },
                                  }),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "pa-0",
                                      attrs: {
                                        plain: "",
                                        href: "http://www.conxepta.com",
                                      },
                                    },
                                    [_vm._v("conxepta.com")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "flex",
                              attrs: {
                                flat: "",
                                tile: "",
                                color: "transparent",
                              },
                            },
                            [
                              _c("v-card-title", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("footer.legalHeadline")) +
                                    " "
                                ),
                              ]),
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-list",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { flat: "", color: "transparent" },
                                    },
                                    [
                                      _c(
                                        "v-list-item-group",
                                        [
                                          _c(
                                            "v-list-item",
                                            {
                                              attrs: {
                                                dense: "",
                                                link: "",
                                                to: { name: "Imprint" },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list-item-icon",
                                                { staticClass: "mr-2" },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      "fa-light fa-chevron-right"
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _c("v-list-item-title", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "footer.linkImprint"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item",
                                            {
                                              attrs: {
                                                dense: "",
                                                link: "",
                                                to: { name: "Privacy" },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list-item-icon",
                                                { staticClass: "mr-2" },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      "fa-light fa-chevron-right"
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _c("v-list-item-title", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "footer.linkPrivacy"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item",
                                            {
                                              attrs: {
                                                dense: "",
                                                link: "",
                                                to: { name: "Terms" },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list-item-icon",
                                                { staticClass: "mr-2" },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      "fa-light fa-chevron-right"
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _c("v-list-item-title", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("footer.linkTerms")
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "flex",
                              attrs: {
                                flat: "",
                                tile: "",
                                color: "transparent",
                              },
                            },
                            [
                              _c("v-card-title", [_vm._v(" Let's connect! ")]),
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        "x-large": "",
                                        icon: "",
                                        color: "#3b5998",
                                        href: "https://www.facebook.com/braendzpro",
                                      },
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v("fa-brands fa-facebook"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        "x-large": "",
                                        icon: "",
                                        color: "#39a9e0",
                                        href: "https://x.com/braendz_com",
                                      },
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v("fa-brands fa-x-twitter"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        "x-large": "",
                                        icon: "",
                                        color: "#006464",
                                        href: "https://www.xing.com/companies/conxeptagmbh",
                                      },
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v("fa-brands fa-xing"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c("v-card-text", { staticClass: "py-2 text-center" }, [
            _vm._v(" Copyright © " + _vm._s(new Date().getFullYear()) + " — "),
            _c("strong", [_vm._v("CONXEPTA GmbH")]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }